.dropDownMenu-Style-Inside {
    transform: translate(340px, -13px) !important;
    height: 350px;
    max-height: 350px;

    width: 234px;
    max-width: 234px;
    overflow: auto;

    padding: 0;
}

.dropDownMenu-Style-List {
    transform: translate(92px, 40px) !important;
}