html,
body {
    margin: 0px;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0,0,0.7);
}


body {
    background-repeat: no-repeat;
    background-size: 100%;
    src: local('Inter'), url('https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Inter');
    font-family: 'Inter', sans-serif;
}