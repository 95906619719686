.login_style {
    height: 100%;
    width: 100%;
}

.background_user_register_img_style {

    background-repeat: no-repeat;
    background-size: 100%;
}

.content_user_register_style {
    /* margin: 0px auto; */
    width: 100%;
    height: 100%;
    padding-left: 20%;
}

.input_user_register_style {
    height: 80px;
    width: 80%;
    border-radius: 10px;
    font-size: medium;

}

.btn_user_register_style {
    height: 180%;
    width: 16%;
    border-radius: 10px;
    font-size: large;
}

.nav_user_register_style {
    margin-left: 75%;
    margin-bottom: 2%;
    margin-top: -5%;
}

.div_nav_user_register_style {
    margin-left: 0px;
}

.span_user_register_style {
    color: white;
    margin-left: 0px;
    margin-bottom: 50px;
    font-size: x-large;
}

.logo_user_register_style {
    margin-top: 20px;
    margin-left: 190%;
}

.div_user_register_style {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
}

.div_logo_user_register_style {
    margin: 0px auto;
    align-items: center;
}

.check_box_user_register_style {
    width: 4%;
    height: 42px;
}

.div_table_user_register_style {
    overflow-y: scroll;
    overflow-x: hidden;
    height: 38rem;
}


/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.btn-delete-style {
    font-size: 50px;
}

.td_left_user_register_style {
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
}

.td_right_user_register_style {
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
}

.div_search_style {
    width: 20%;
    left: 80%;
    display: flex;
}

@media screen and (max-width: 1000px) {
    .content_user_register_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_user_register_style {
        font-size: 300%;
        margin-left: 390%;
        padding-top: 40%;
    }

    .span_user_register_style {
        color: white;
        margin-left: 0px;
        font-size: 400%;
    }

    .input_user_register_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_user_register_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_user_register_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_user_register_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_user_register_style {
        padding-top: 30%;
        position: relative;
        text-align: center;
    }

    .background_user_register_img_style {
        background-repeat: no-repeat;
        background-size: 100% auto;
        background-position: center
    }

    .check_box_user_register_style {
        width: 15%;
        height: 110px;
    }
    
}

@media screen and (max-height:1800px) and (max-width:1000px) {

    .content_user_register_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_user_register_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_user_register_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_user_register_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_user_register_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_user_register_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_user_register_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_user_register_style {
        padding-top: 70%;
        position: relative;
        text-align: center;
    }

    .background_user_register_img_style {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center
    }

    .check_box_user_register_style {
        width: 15%;
        height: 110px;
    }
}

@media screen and (max-height:1500px) and (max-width:1000px) {

    .content_user_register_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_user_register_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_user_register_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_user_register_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_user_register_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_user_register_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_user_register_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_user_register_style {
        padding-top: 25%;
        position: relative;
        text-align: center;
    }

    .background_user_register_img_style {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center
    }

    .check_box_user_register_style {
        width: 15%;
        height: 110px;
    }
}


@media screen and (max-height:1500px) and (max-width:1600px) {

    .content_user_register_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_user_register_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_user_register_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_user_register_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_user_register_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_user_register_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_user_register_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_user_register_style {
        padding-top: 25%;
        position: relative;
        text-align: center;
    }

    .background_user_register_img_style {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center
    }

    .check_box_user_register_style {
        width: 15%;
        height: 110px;
    }

    .div_search_style {
        width: 30%;
        left: 70%;
        display: flex;
    }
}


@media screen and (max-height:900px) and (max-width:900px) {

    .content_user_register_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_user_register_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_user_register_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_user_register_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_user_register_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_user_register_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_user_register_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_user_register_style {
        padding-top: 25%;
        position: relative;
        text-align: center;
    }

    .background_user_register_img_style {
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center
    }

    .check_box_user_register_style {
        width: 15%;
        height: 110px;
    }

    .div_search_style {
        width: 40%;
        left: 60%;
        display: flex;
    }
    .btn_search_style{
        margin-top: 0 !important;
    }
}