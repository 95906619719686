/* width */
::-webkit-scrollbar {
    width: 10px;
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.th-Tabela-Register-Hours {
    background-color: transparent !important;
    font-size: 20px;
}

.td-Tabela-Register-Hours {
    background-color: rgba(54, 54, 54, 1) !important;
    height: 100px;
    vertical-align: middle;
    font-size: 20px;
}

.td-Tabela-Informations {
    background-color: rgba(150, 150, 150, 1) !important;
    color: black !important;
    height: 100px;
    vertical-align: middle;
    font-size: 20px;
}

.td-Radius-Left {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.td-Radius-Right {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.table-Register-Hours-Style {
    border-collapse: separate;
    border-spacing: 0 10px;
}

.div-Table-Register-Hours-Style {
    max-width: 70%;
    margin-left: 6%;
}

.div-Btn-Register-Hours-add-Spent-Hours {
    position: fixed;
    bottom: 4%;
    right: 2%;
}

.btn-Register-Hours-add-Spent-Hours {
    font-size: 30px;
    border-radius: 90%;
    width: 60px;
    height: 60px;
    text-align: center;
}

.div-Register-Hours {
    margin-top: 5%;
}

.div-Register_hours-date {
    padding-left: 6%;
    margin-bottom: 2%;
}

.hTag {
    color: white;
}

.div-Register-Hours-Table-Style {
    overflow-y: auto;
    max-height: 30rem;
}

.btn-Edit-Style {
    font-size: 40px;
    cursor: pointer;
}

.input-Date-Register-Hours-Div {
    display: flex;
    width: 100%;
}


.css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root {
    color: rgb(255, 255, 255) !important;
}

.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(255 255 255) !important;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border-color: rgb(255 255 255) !important;
}

.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    color: white !important;
    margin-top: -2% !important;
}

.css-hdw1oc {
    float: left !important;
}

.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
    color: rgb(255 255 255) !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root {
    background-color: rgba(36, 36, 36, 1) !important;
}

.css-sghohy-MuiButtonBase-root-MuiButton-root:hover {
    background-color: rgba(30, 30, 30, 1) !important;
}


@media only screen and (min-width: 400px) and (max-width:500px) {

    .td-Tabela-Register-Hours {
        background-color: rgba(54, 54, 54, 1) !important;
        height: 70px;
        vertical-align: middle;
        font-size: 12px;
    }

    .div-Table-Register-Hours-Style {
        max-width: 90%;
    }

    .div-Btn-Register-Hours-add-Spent-Hours {
        position: fixed;
        bottom: 2%;
        right: 4%;
    }

    .btn-Register-Hours-add-Spent-Hours {
        font-size: 25px;
        border-radius: 90%;
        width: 50px;
        height: 50px;
        text-align: center;
    }

    .div-Register-Hours {
        margin-top: 5%;
        margin-right: 2%;
    }


    .btn-Edit-Style {
        font-size: 35px;
        cursor: pointer;
    }


    .table-responsive {
        max-height: 23rem;
    }

}


@media only screen and (min-width: 350px) and (max-width:400px) {

    .td-Tabela-Register-Hours {
        background-color: rgba(54, 54, 54, 1) !important;
        height: 70px;
        vertical-align: middle;
        font-size: 12px;
    }

    .div-Table-Register-Hours-Style {
        max-width: 90%;
    }

    .div-Btn-Register-Hours-add-Spent-Hours {
        position: fixed;
        bottom: 2%;
        right: 4%;
    }

    .btn-Register-Hours-add-Spent-Hours {
        font-size: 25px;
        border-radius: 90%;
        width: 50px;
        height: 50px;
        text-align: center;
    }

    .div-Register-Hours {
        margin-top: 5%;
        margin-right: 2%;
    }


    .btn-Edit-Style {
        font-size: 35px;
        cursor: pointer;
    }

    .table-responsive {
        max-height: 20rem;
    }
}

@media only screen and (min-width: 300px) and (max-width:400px) {

    .td-Tabela-Register-Hours {
        background-color: rgba(54, 54, 54, 1) !important;
        height: 70px;
        vertical-align: middle;
        font-size: 12px;
    }

    .div-Table-Register-Hours-Style {
        max-width: 90%;
    }

    .div-Btn-Register-Hours-add-Spent-Hours {
        position: fixed;
        bottom: 2%;
        right: 4%;
    }

    .btn-Register-Hours-add-Spent-Hours {
        font-size: 25px;
        border-radius: 90%;
        width: 50px;
        height: 50px;
        text-align: center;
    }

    .div-Register-Hours {
        margin-top: 5%;
        margin-right: 2%;
    }


    .btn-Edit-Style {
        font-size: 35px;
        cursor: pointer;
    }

    .table-responsive {
        max-height: 13rem;
    }

}