.login_style {
    height: 100%;
    width: 100%;
}

html,
body {
    margin: 0px;
    height: 100%;
    width: 100%;
}


body {
    background-size: 100%;
}


.content_login_style {
    width: 100%;
    height: 100%;
    padding-left: 10%;
    margin-top: 8%;
}

.input_login_style {
    height: 80px;
    width: 80%;
    border-radius: 10px;
    font-size: medium;

}

.btn_login_style {
    height: 180%;
    width: 16%;
    border-radius: 10px;
    font-size: large;
}

.span_login_style {
    color: white;
    margin-left: 0px;
    margin-bottom: 50px;
    font-size: x-large;
}

.logo_login_style {
    margin-top: 20px;
}

.div_login_logo {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px;
}

.div_login_style {
    height: 100%;
}

.div_logo_login_style {
    align-items: center;
}

.div_login_span_style {
    margin-bottom: 50px;
}

@media screen and (max-width: 800px) {
    .content_login_style {
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 30%;
    }

    .span_login_style {
        color: white;
        margin-left: 0px;
        font-size: 150%;
    }

    .input_login_style {
        height: 70px;
        width: 100%;
        border-radius: 10px;
        font-size: 100%;
    }

    .btn_login_style {
        height: 60px;
        width: 40%;
        border-radius: 10px;
        font-size: 100%;
    }

    .div_login_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_login_style {
        margin: 0px auto;
        width: 20%;
    }

    .div_logo_login_style {
        padding-top: 100%;
        position: relative;
        text-align: center;
    }

    .div_btn_entrar_style {
        margin-top: 20px;
    }

    .div_login_logo {
        display: flex;
        justify-content: center;
        padding-top: 20px;
    }
}


/* @media screen and (max-height:1800px) and (max-width:800px) {

    .content_login_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_login_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_login_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_login_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_login_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .eng_pt_style {
        color: white;
    }

    .logo_login_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_login_style {
        padding-top: 70%;
        position: relative;
        text-align: center;
    }

}

@media screen and (max-height:1500px) and (max-width:1000px) {

    .content_login_style {
        padding-left: 10%;
        padding-right: 10%;
    }

    .nav_login_style {
        font-size: 300%;
        margin-left: 70%;
        padding-top: 10%;
    }

    .span_login_style {
        color: white;
        margin-left: 0px;
        font-size: 500%;
    }

    .input_login_style {
        height: 150px;
        width: 100%;
        border-radius: 10px;
        font-size: 300%;
    }

    .btn_login_style {
        height: 150px;
        width: 30%;
        border-radius: 10px;
        font-size: 300%;
    }

    .div_login_style {
        background-color: rgba(0, 0, 0, 0)
    }

    .eng_pt_style {
        color: white;
    }

    .logo_login_style {
        margin: 0px auto;
        height: 25%;
        width: 25%;
    }

    .div_logo_login_style {
        padding-top: 25%;
        position: relative;
        text-align: center;
    }

} */