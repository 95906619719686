.nav-new-img {
    width: 20%;
    margin-left: 7%;
    margin-right: 7%;
    margin-top: 3%;
    margin-bottom: 3%;
}

.nav-Style {
    background-color: rgba(39, 39, 39, 1);
}

.div-Collapse-Style {
    display: flex;
    justify-content: flex-end;
}

.div-DropDownToggle {
    display: flex;
    text-align: end;
}

.btn-DropDownToggle-Style {
    background-color: transparent;
    border-color: transparent;
    color: white;
    font-size: 30px;
}

.dropDownItem01-Style {
    background-color: rgba(54, 54, 54, 1);
    padding: 1.25rem 3rem;
}

.dropDownItem01-Style:hover {
    background-color: rgba(54, 54, 54, 1) !important;
}

.dropDownItem02-Style {
    background-color: rgba(71, 71, 71, 1);
    padding: 1.25rem 3rem;
}

.dropDownItem02-Style {
    background-color: rgba(71, 71, 71, 1) !important;
}


.dropDownMenu-Style {
    margin-top: -1px;
    background-color: transparent;
    border-color: transparent;
}

.container-fluid {
    padding-right: 0;
}


@media only screen and (max-width: 800px) {
    .div-Collapse-Style {
        display: flex;
        justify-content: flex-start;
    }

    .collapse:not(.show) {
        display: flex;
    }

    .navbar>.container,
    .navbar>.container-fluid,
    .navbar>.container-lg,
    .navbar>.container-md,
    .navbar>.container-sm,
    .navbar>.container-xl,
    .navbar>.container-xxl {
        display: flex;
        flex-wrap: inherit;
        align-items: center;
    }

    .div-DropDownToggle {
        display: flex;
        text-align: start;
    }

    .div-Collapse-Style {
        display: flex;
    }

    .dropDownMenu-Style {
        margin-top: 1px;
        background-color: transparent;
        border-color: transparent;
    }

    [type=button]:not(:disabled),
    [type=reset]:not(:disabled),
    [type=submit]:not(:disabled),
    button:not(:disabled) {
        cursor: pointer;
        margin-top: -9%;
    }

    .dropdown-menu-dark .dropdown-item {
        margin-top: -1px;
        margin-left: -1px;
    }

}