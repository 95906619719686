.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: right;
    align-items: center;
}


.popupInner {
    position: relative;
    padding: 50px;
    height: 75%;
    width: 90%;
    margin-top: 5%;
    margin-right: 5%;
    background-color: rgba(54, 54, 54, 1);
    color: #FFF;
    border-radius: 10px;
}

.popupInner .closePopup {
    position: absolute;
    top: 9px;
    width: 30px;
    height: 30px;
    right: 9px;
    border-color: transparent;
}

.form-check {
    margin: inherit;
}

legend {
    margin-bottom: 5rem;
}

.dropdown-popup {
    color: rgba(0, 0, 0, 0.8);
    background-color: white;
    border-radius: 0;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
}

.imgpopupsucess,
.imgpopupError {
    max-width: 60%;
    width: 30%;
    margin: -12% 35% 15%;
}

.div-Btn-Close-Popup {
    display: flex;
    justify-content: flex-end;
}

.div-Title-Popup-Style {
    display: flex;
}

.popup-Title-Client {
    display: flex;
    width: 45%;
}

.popup-Title-Project {
    display: flex;
    width: 15%;
}

.popup-Title-Description {
    display: flex;
}

.popup-Div-Client-Hour-Field {
    display: flex;
    position: relative;
}

.popup-Div-Input-Client-Style {
    width: 40%;
    height: 0;
}

.popup-Div-Project-Title {
    margin-top: 3%;
}

.popup-Div-Project-Field {
    width: 40%;
}

.popup-Div-Hour-Field {
    width: 12%;
    height: 0;
    margin-left: 5%;
    margin-right: 5%;
}

.popup-Input-Description-Field {
    padding-bottom: 200px;
    position: absolute;
    width: 38.2%;
}

.popup-Div-Border-Style {
    border: 1px solid rgba(100, 100, 100, 1);
    margin-top: 10%;
}

.popup-Div-Btn {
    display: flex;
    position: absolute;
    bottom: 4%;
    right: 2%;
}

.popup-Btn-Cancel-Style {
    width: 150px;
    height: 55px;
}

.popup-Div-Btn-Save {
    margin-left: 10px;
}

.popup-Btn-Save-Style {
    width: 150px;
    height: 55px;
}


@media only screen and (max-width: 450px) {

    .popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        min-height: 500px;
        max-height: 750px;
        display: flex;
        justify-content: right;
        align-items: center;
    }


    .popupInner {
        position: relative;
        padding: 10px;
        height: 90%;
        width: 90%;
        margin-top: 0;
        margin-right: 5%;
        background-color: rgba(54, 54, 54, 1);
        color: #FFF;
        border-radius: 10px;
    }

    .popupInner .closePopup {
        position: absolute;
        top: 9px;
        width: 30px;
        height: 30px;
        right: 9px;
        border-color: transparent;
    }

    .dropdown-popup {
        color: rgba(0, 0, 0, 0.8);
        background-color: white;
        border-radius: 0;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
    }

    .popup-Title-Project {
        display: flex;
        width: 15%;
        margin-left: 10%;
    }


    .popup-Div-Project-Title {
        margin-top: 10%;
    }

    .popup-Div-Hour-Field {
        margin-left: 15%;
        margin-right: 5%;
        min-width: 8rem;
    }

    .popup-Input-Description-Field {
        padding-bottom: 10px;
        position: absolute;
        width: 95%;
    }

    .popup-Div-Btn {
        left: 3%;
    }

    .popup-Btn-Cancel-Style {
        width: 100px;
        height: 45px;
    }

    .popup-Btn-Save-Style {
        width: 100px;
        height: 45px;
    }
}

