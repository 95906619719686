.div_style_change_password {
    height: 70%;

    position: relative;
    margin-left: auto;
    margin-right: auto;

}

.logo_style_change_password {

    /* margin: auto;
    display: block; */
    /* margin-left: 26.5%; */
    margin-left: 9%;
    margin-top: 9%;
    margin-bottom: 3%;
    height: 130px;
    width: 130px;
}

.div_input_style_change_password {
    margin-left: 10%;
    width: 80%;
    margin: 0px auto;
}

.div_h1_style_change_password {

    position: relative;
    margin-left: 0 auto;
    margin-bottom: 0 auto;
    /* text-align: center; */
    margin-left: 9%;
    margin-bottom: 7%;
}

.button_style_change_password {
    /* display: block; */
    margin: auto;
    width: 300px;
    height: 60px;
    border-radius: 20px;
    margin-top: 5%;
}